import ApiService from "@/services/pamisDashboardApi.service"
import axios from '@axios'
/**
 * @typedef {Object} WorkOrderParam
 * @property {number} id - The ID of the work order
 * @property {string} name - The name of the work order
 * @property {string} assignee - The assignee of the work order
 * @property {string} startDate - The start date in ISO 8601 format
 * @property {string} endDate - The end date in ISO 8601 format
 * @property {number} priority - The priority level
 * @property {number} status - The status of the work order
 */
// const baseURL = process.env.VUE_APP_API_URL + '/pamis/pda'
var baseURL = "https://limaapitest.eldor.com.tr/pamis/pda"
// const identityBaseURL = process.env.VUE_APP_API_URL + "/pamis/identity-v2"
const identityBaseURL = "https://limaapitest.eldor.com.tr/pamis/identity-v2"
export default {
    namespaced: true,
    state: {
        workOrderItems: [],
        workOrderFields: {
            priorities: [
                { id: 1, name: "Low" },
                { id: 2, name: "Medium" },
                { id: 3, name: "High" },
            ],
            statuses: [

                { id: 1, name: "To Do" },
                { id: 2, name: "Ongoing" },
                { id: 3, name: "Done" },
                { id: 4, name: "On Hold" },

            ],
            workCenters: [
                { id: 1, name: "L101" },
                { id: 2, name: "L102" },
                { id: 3, name: "L103" },
                { id: 4, name: "L104" },
                { id: 5, name: "L105" },
                { id: 6, name: "L106" },
            ]
        },
        assignees: [],
        assigneeMap: new Map(),
        workCenterMap: new Map()
    },
    getters: {
        GetWorkOrderItems(state) {
            return state.workOrderItems
        },
        GetWorkOrderFields(state) {
            return state.workOrderFields
        },
        GetAssignees(state) {
            return state.assignees
        },
        GetAssigneeMap(state) {
            return state.assigneeMap
        },
        GetWorkCenterMap(state) {
            return state.workCenterMap
        }

    },

    actions: {
        GetAssignees(ctx) {
            return new Promise((resolve, reject) => {
                axios.get(`${identityBaseURL}/Identity/GetUsers`)
                    .then(response => {
                        const data = response.data.map((e) => {
                            return {
                                id: e.id,
                                name: `${e.firstName} ${e.lastName}`
                            }
                        })
                        ctx.commit("SetAssignees",
                            data
                        )
                        resolve(data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        GetWorkOrderItems(ctx) {
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/WorkOrder`)
                    .then(response => {
                        ctx.commit("SetWorkOrderItems",
                            response.data
                        )
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        GetWorkOrderFields(ctx) {
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/WorkOrder/GetWorkOrderFields`)
                    .then(response => {
                        ctx.commit("SetWorkOrderFields",
                            response.data
                        )
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        /**
         * Send the PUT HTTP request
         * @param {WorkOrderParam} param - The parameter object
         * @returns {Promise} - A promise that resolves with the server response
         */
        AddNewWorkOrder(ctx, param) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${baseURL}/WorkOrder/Create`, param)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        /**
         * Send the PUT HTTP request
         * @param {WorkOrderParam} param - The parameter object
         * @returns {Promise} - A promise that resolves with the server response
         */
        EditWorkOrder(ctx, param) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${baseURL}/WorkOrder/Update`, param)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        /**
         * 
         * @param {context} ctx 
         * @param {number} param ID of workorder 
         * @returns 
         */
        DeleteWorkOrder(ctx, param) {
            console.log(param)
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${baseURL}/WorkOrder/Delete`, {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: {
                            id: param
                        }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            })
        }
    },
    mutations: {
        SetAssignees(state, assignees) {
            state.assignees = assignees
            const map = new Map();
            for (let i in assignees) {
                map.set(assignees[i].id, assignees[i].name);
            }

            state.assigneeMap = map
        },
        SetWorkOrderItems(state, workOrderItems) {
            state.workOrderItems = workOrderItems
        },
        SetWorkOrderFields(state, workOrderFields) {
            state.workOrderFields.workCenters = workOrderFields.workCenters
            state.workOrderFields.priorities = workOrderFields.priorities
            state.workOrderFields.statuses = workOrderFields.statuses
            
            const workCenterMap = new Map();
            for (let i in workOrderFields.workCenters) {
                workCenterMap.set(workOrderFields.workCenters[i].id, workOrderFields.workCenters[i].name);
            }
            state.workCenterMap = workCenterMap
        }
    },
}
